export const routeNames = {
  dashboard: 'Dashboard',
  login: 'Login',
  about: 'About',
  users: 'Users',
  userDetails: 'UserDetails',
  vehicles: 'Vehicles',
  vehicleDetails: 'VehicleDetails',
  trips: 'Trips',
  tripDetails: 'TripDetails',
  reviews: 'Reviews',
  reviewDetails: 'ReviewDetails',
  settings: 'Settings',
  securityGroups: 'SecurityGroups',
  notFound: 'NotFound',
  restricted: 'Rescticted',
}
