import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { UserType } from '@/models/user'

import { routeNames } from './route-names'
import { layoutNames } from '@/layouts/layout-names'

import {
  castBooleans,
  castPagination,
  castStrings,
  castStringsArray,
} from '@/helpers/query'
import { getScope, RbacAction, RbacEntity } from '@/helpers/rbac'

import authMiddleware from '@/middlewares/auth'
import rbacGuard from '@/middlewares/rbac'

import Dashboard from '@/views/Dashboard.vue'
import NotFound from '@/views/NotFound.vue'

const { READ } = RbacAction

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routeNames.dashboard,
    meta: { layout: layoutNames.main },
    component: Dashboard,
  },
  {
    path: '/login',
    name: routeNames.login,
    meta: {
      layout: layoutNames.empty,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/about',
    name: routeNames.about,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/users',
    props: ({ query }) => ({
      query: {
        ...castPagination(query),
        ...castStrings(query, [
          'status',
          'isCommercial',
          'search_admin_poa',
          'is_admin_poa',
          'q',
          'firstName',
          'lastName',
        ]),
        ...castBooleans(query, ['misconduct', 'isBalance']),
      },
    }),
    name: routeNames.users,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.USERS)],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users/List.vue'),
  },
  {
    path: '/users/:id',
    name: routeNames.userDetails,
    props: true,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.USERS)],
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ '../views/Users/Details/index.vue'
      ),
  },
  {
    path: '/vehicles',
    name: routeNames.vehicles,
    props: ({ query }) => ({
      query: {
        ...castPagination(query),
        ...castStrings(query, ['status', 'user_id']),
      },
    }),
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.CARS)],
    },
    component: () =>
      import(/* webpackChunkName: "vehicles" */ '../views/Vehicles/List.vue'),
  },
  {
    path: '/vehicles/:id',
    name: routeNames.vehicleDetails,
    props: true,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.CARS)],
    },
    component: () =>
      import(
        /* webpackChunkName: "vehicles" */ '../views/Vehicles/Details/index.vue'
      ),
  },
  {
    path: '/trips',
    name: routeNames.trips,
    props: ({ query }) => ({
      query: {
        ...castPagination(query),
        ...castBooleans(query, [
          'cancelled',
          'damaged',
          'checkIn',
          'checkOut',
          'isFinalize',
        ]),
        ...castStrings(query, [
          'car_profile_id',
          'host_id',
          'renter_id',
          'insurance_scripts',
        ]),
        failedPayments: Boolean(query.failedPayments) || undefined,
        statuses: castStringsArray(query.statuses),
      },
    }),
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.TRIPS)],
    },

    component: () =>
      import(/* webpackChunkName: "trips" */ '../views/Trips/List.vue'),
  },
  {
    path: '/trips/:id',
    name: routeNames.tripDetails,
    props: true,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.TRIPS)],
    },
    component: () =>
      import(
        /* webpackChunkName: "trips" */ '../views/Trips/Details/index.vue'
      ),
  },
  {
    path: '/settings',
    name: routeNames.settings,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.APP_SETTINGS)],
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
  },
  {
    path: '/security-groups',
    name: routeNames.securityGroups,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.ADMIN_PROFILES)],
    },
    component: () =>
      import(
        /* webpackChunkName: "security groups" */ '../views/SecurityGroups.vue'
      ),
  },
  {
    path: '/reviews',
    name: routeNames.reviews,
    props: ({ query }) => ({
      query: {
        ...castPagination(query),
        userType:
          query.userType === UserType.HOST ? UserType.HOST : UserType.RENTER,
      },
    }),
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.USERS)],
    },
    component: () =>
      import(/* webpackChunkName: "reviews" */ '../views/Reviews/List.vue'),
  },
  {
    path: '/reviews/:id',
    props: true,
    name: routeNames.reviewDetails,
    meta: {
      layout: layoutNames.main,
      scopes: [getScope(READ, RbacEntity.USERS)],
    },
    component: () =>
      import(/* webpackChunkName: "reviews" */ '../views/Reviews/Details.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: routeNames.notFound,
    meta: { layout: layoutNames.empty },
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(authMiddleware)
router.beforeEach(rbacGuard)

export default router
